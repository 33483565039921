@font-face {
  font-family: Karbon;
  src: url("karbon-hairline.65c7e6bf.woff2") format("woff2");
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: Inter;
  src: url("Inter-Thin.ac579d62.ttf") format("truetype");
  font-weight: 100;
}

@font-face {
  font-family: Daniel;
  src: url("daniel.97683689.ttf") format("truetype");
  font-weight: 400;
}

:root {
  --light: #fff;
  --dark: #000;
  --cursor-stroke: #000;
  --cursor-fill: #000;
}

[data-theme="light"] {
  --light: #fff;
  --dark: #000;
}

[data-theme="dark"] {
  --light: #000;
  --dark: #fff;
  --cursor-stroke: #fff;
  --cursor-fill: #fff;
}

.cursor {
  display: none;
}

@media (any-pointer: fine) {
  .cursor {
    z-index: 102;
    pointer-events: none;
    display: block;
    position: fixed;
    top: 0;
    left: 0;
  }

  .cursor__inner {
    fill: var(--cursor-fill);
    stroke: var(--cursor-stroke);
    stroke-width: var(--cursor-stroke-width);
  }
}

* {
  margin: 0;
  padding: 0;
  font-family: Karbon, sans-serif;
  font-weight: 100;
}

body::-webkit-scrollbar {
  display: none;
}

#main::-webkit-scrollbar {
  display: none;
}

body {
  -ms-overflow-style: none;
  scrollbar-width: none;
  --cursor-stroke-width: 1px;
  background: var(--light);
  color: var(--dark);
}

body, #overlay, #author, .moon {
  transition: background-color .4s;
}

button {
  appearance: none;
  cursor: none;
  color: var(--dark);
  background: none;
  border: none;
  outline: none;
}

h1 {
  text-transform: lowercase;
  font-size: 11em;
  display: none;
}

p, i, li {
  font-family: Inter, sans-serif;
  font-size: 1em;
}

em {
  font-size: 1.5em;
  font-style: normal;
}

h1, h2, h3, em, p, sup, ol li {
  cursor: none;
}

#main {
  display: none;
}

.scroll-container {
  will-change: transform;
  height: 100vh;
}

data-scroll-section {
  will-change: "transform";
}

section {
  grid-template-rows: repeat(12, 1fr);
  grid-template-columns: repeat(12, 1fr);
  min-width: 100%;
  height: 100%;
  display: grid;
}

.switch-container {
  z-index: 98;
  opacity: 0;
  place-items: center;
  display: flex;
  position: fixed;
  top: 2em;
}

#switch {
  filter: none;
  background-color: #0000;
  border: none;
  border-radius: 9999px;
  align-items: stretch;
  width: 5rem;
  height: 5rem;
  display: flex;
  position: relative;
  overflow: hidden;
}

input {
  z-index: 1;
  appearance: none;
  cursor: none;
  background-color: var(--dark);
  border: 0;
  border-radius: 9999px;
  outline: none;
  width: 2.5rem;
  height: 2.5rem;
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

input :focus-visible, input :focus {
  outline: none;
}

.moon {
  z-index: 1;
  background-color: var(--light);
  pointer-events: none;
  border-radius: 9999px;
  width: 2.5rem;
  height: 2.5rem;
  position: absolute;
  top: 50%;
  left: 70%;
  transform: translate(-50%, -50%);
}

input:checked ~ .moon {
  background-color: var(--light);
  border: 0;
  border-radius: 9999px;
  width: 0;
  height: 2.5rem;
  left: 100%;
  transform: translate(-50%, -50%);
}

section.intro {
  justify-content: center;
  place-items: center;
}

.title_container {
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 80%;
  display: flex;
}

#figureContainer {
  opacity: 0;
  object-fit: cover;
  justify-content: center;
  align-items: center;
  width: 50%;
  height: 50%;
  display: flex;
  overflow: hidden;
}

#figureContainer img {
  width: 1180px;
  height: auto;
}

.introContainer {
  grid-area: 2 / 2 / 12 / 12;
  justify-content: center;
  align-items: center;
  width: 800px;
  height: 400px;
  display: flex;
  position: relative;
}

.introContainer img {
  aspect-ratio: 16 / 9;
  object-fit: cover;
}

.introText {
  cursor: none;
  z-index: 11;
  align-items: center;
  height: 8em;
  display: inline-flex;
  position: absolute;
  overflow: hidden;
}

.introText.initial:first-of-type {
  top: -15%;
}

.introText.initial:nth-of-type(2) {
  bottom: -15%;
}

.introText.top, .introText.bottom {
  transform-origin: 100% 100%;
  height: 2em;
  right: 1em;
}

.introText.top h1, .introText.bottom h1 {
  font-size: 1.5em;
  display: flex;
}

.introText.top, em.top {
  top: 0;
  transform: translateY(10%)rotate(-90deg);
}

.introText.bottom, em.bottom {
  bottom: 15%;
  transform: translateY(10%)rotate(-90deg);
}

em {
  text-align: center;
  transform-origin: 100% 100%;
  position: absolute;
}

#menuButton {
  z-index: 101;
  text-align: center;
  opacity: 0;
  font-size: 1.2em;
  display: none;
  position: fixed;
  bottom: 2.5em;
  left: 50%;
  transform: translate(-50%, -50%);
}

#author {
  background: var(--light);
  z-index: 101;
  width: 0;
  height: 100%;
  position: fixed;
  top: 0;
  right: 0;
}

#overlay {
  background: var(--light);
  z-index: 10;
  will-change: width;
  width: 5%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
}

#overlay .overlay__content {
  opacity: 0;
  grid-template-rows: repeat(10, 1fr);
  grid-template-columns: repeat(10, 1fr);
  width: 100%;
  height: 100%;
  display: grid;
}

#overlay .overlay__content h2 {
  text-transform: lowercase;
  grid-area: 2 / 2 / span 2 / 10;
  align-self: center;
  font-size: 5em;
}

#overlay .overlay__content .lang-fr, #overlay .overlay__content .lang-en {
  text-transform: uppercase;
  grid-row: 4 / span 1;
  align-self: center;
  font-size: 1.2em;
}

#overlay .overlay__content .lang-en {
  grid-column: 7 / 7;
}

#overlay .overlay__content .lang-fr {
  grid-column: 2 / 2;
}

#overlay .overlay__content__fr, #overlay .overlay__content__en {
  grid-row: 5 / span 5;
  align-self: center;
}

#overlay .overlay__content__fr {
  color: var(--dark);
  text-transform: uppercase;
  grid-column: 2 / 5;
}

#overlay .overlay__content__en {
  color: var(--dark);
  text-transform: uppercase;
  grid-column: 7 / 10;
}

#overlay .overlay__content p {
  margin-bottom: 1em;
}

.a, .b, .c, .d, .e {
  object-fit: cover;
  overflow: hidden;
}

.a, .gallery-item .b, .single-item .c {
  width: 100%;
}

.d {
  width: 60%;
}

.single-item .b {
  width: 50%;
}

.e {
  width: 40%;
  height: auto;
}

.a {
  aspect-ratio: 4 / 3;
}

.b {
  aspect-ratio: 1;
}

.c {
  aspect-ratio: 16 / 9;
}

.d {
  aspect-ratio: 9 / 16;
}

.e {
  aspect-ratio: 3 / 4;
}

picture {
  text-align: center;
}

.single-item, .gallery-item {
  grid-row: 3 / span 8;
  align-self: center;
  height: 100%;
  display: grid;
  overflow: hidden;
}

.single-item {
  grid-column: 3 / span 8;
}

.gallery-item {
  grid-column: 2 / span 10;
  gap: 2em;
}

.gallery-item figure:first-of-type {
  grid-column: 2 / span 4;
  display: grid;
}

.gallery-item figure:nth-of-type(2) {
  grid-column: 8 / span 4;
}

.single-item figure, .gallery-item figure {
  object-fit: cover;
  align-self: center;
  display: grid;
  overflow: hidden;
}

.credits_content__pagination {
  grid-area: 3 / 2 / span 9 / span 3;
  align-self: flex-end;
}

.credits_content__pagination li:before {
  content: "";
  width: 2em;
  display: inline-flex;
}

.credits_content__pagination ol li {
  line-height: 1.4em;
}

.credits_content__title {
  text-align: center;
  grid-area: 6 / 5 / span 2 / span 4;
  align-self: center;
  display: grid;
  position: relative;
}

.credits_content__title sup {
  padding: 0 1.2em;
  font-size: 1.2em;
  position: absolute;
  top: -1em;
}

.credits_content__title sup.left {
  left: 0;
}

.credits_content__title sup.right {
  right: 0;
}

.credits_content__title h3 {
  letter-spacing: .1em;
  text-align: center;
  font-size: 5em;
  position: relative;
}

.credits_content__quote {
  white-space: wrap;
  grid-area: 3 / -4 / span 2 / span 2;
}

.credits_content__quote p {
  font-family: Daniel, sans-serif;
  font-size: 1.2em;
}

.credits_content__text {
  text-transform: uppercase;
  white-space: wrap;
  grid-area: 6 / -4 / span 6 / span 2;
  align-self: flex-end;
}

.credits_content__text p {
  margin-bottom: 1.2em;
  line-height: 1.3em;
}

.container-mobile {
  background-color: #000;
  flex-flow: column;
  justify-content: space-around;
  align-items: center;
  max-width: 100%;
  height: 100vh;
  padding: 5%;
  display: flex;
  overflow: hidden;
}

.container-mobile .title {
  width: 80%;
}

.container-mobile .title h1 {
  text-align: center;
  justify-content: center;
  margin: 5% 0;
  font-size: 5em;
  display: flex;
}

.container-mobile .title h2 {
  text-align: center;
  text-transform: none;
  font-size: 1.5em;
}

.container-mobile h1, .container-mobile h2, .container-mobile p {
  color: #fff;
}

.container-mobile p {
  font-size: .9em;
}

.loader {
  text-align: center;
  z-index: 200;
  text-align: center;
  justify-content: center;
  align-self: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  display: flex;
  position: fixed;
}

.loader_container {
  justify-content: center;
  align-items: center;
  height: 8em;
  display: flex;
  overflow: hidden;
}

.loader_container__inner {
  text-align: center;
  font-size: 9em;
}

@media (width <= 767px) {
  .scroll-container {
    display: none !important;
  }
}

@media (width >= 768px) and (width <= 768px) and (orientation: portrait) {
  h1 {
    font-size: 9em;
  }

  .introText {
    height: 11em;
  }

  .introContainer {
    width: 350px;
    height: 500px;
  }

  #figureContainer {
    height: 70%;
  }

  #figureContainer img {
    width: 1000px;
  }

  #overlay .overlay__content h2 {
    font-size: 3em;
  }

  #overlay .overlay__content .lang-en, #overlay .overlay__content .lang-fr {
    grid-row: 3 / span 1;
    align-self: flex-end;
  }

  #overlay .overlay__content .lang-fr {
    grid-column: 2 / 3;
  }

  #overlay .overlay__content .lang-en {
    grid-column: 7 / 8;
  }

  #overlay .overlay__content__fr, #overlay .overlay__content__en {
    grid-row: 4 / span 5;
    align-self: center;
  }

  .credits_content__pagination {
    column-count: 2;
    grid-area: 3 / 3 / span 4 / span 9;
    align-self: flex-start;
  }

  .credits_content__pagination ol li {
    line-height: 1.1em;
  }

  .credits_content__quote {
    display: none;
  }

  .credits_content__title {
    grid-area: 7 / 3 / span 2 / span 8;
  }

  .credits_content__title sup {
    padding: 0;
  }

  .credits_content__title sup.left {
    left: 2em;
  }

  .credits_content__title sup.right {
    right: 2em;
  }

  .credits_content__text {
    grid-area: 8 / 3 / span 5 / span 8;
    align-self: center;
    gap: 4em;
  }
}

@media (width >= 769px) and (width <= 1024px) and (orientation: portrait) {
  h1 {
    font-size: 11em;
  }

  .introText {
    height: 9em;
  }

  .introText.initial:first-of-type {
    top: -10%;
  }

  .introText.initial:nth-of-type(2) {
    bottom: -10%;
  }

  .introContainer {
    width: 500px;
    height: 500px;
  }

  #figureContainer {
    height: 50%;
  }

  #figureContainer img {
    width: 1200px;
  }

  #overlay .overlay__content h2 {
    font-size: 5em;
  }

  #overlay .overlay__content .lang-en, #overlay .overlay__content .lang-fr {
    grid-row: 3 / span 2;
    align-self: flex-end;
    font-size: 1.4em;
  }

  #overlay .overlay__content .lang-fr {
    grid-column: 2 / 3;
  }

  #overlay .overlay__content .lang-en {
    grid-column: 7 / 8;
  }

  #overlay .overlay__content__fr, #overlay .overlay__content__en {
    grid-row: 4 / span 6;
    align-self: center;
  }

  .credits_content__pagination {
    column-count: 2;
    grid-area: 3 / 3 / span 4 / span 9;
    align-self: flex-start;
    gap: 4em;
  }

  .credits_content__quote {
    display: none;
  }

  .credits_content__title {
    grid-area: 7 / 3 / span 2 / span 8;
    justify-content: center;
  }

  .credits_content__title h3 {
    font-size: 7em;
  }

  .credits_content__title sup {
    padding: 0;
  }

  .credits_content__title sup.left {
    left: 2em;
  }

  .credits_content__title sup.right {
    right: 2em;
  }

  .credits_content__text {
    column-count: 2;
    grid-area: 8 / 3 / span 5 / span 8;
    align-self: center;
    gap: 4em;
  }
}

@media (width >= 768px) and (width <= 1023px) and (orientation: landscape) {
  h1 {
    font-size: 8em;
  }

  .introContainer {
    width: 500px;
    height: 300px;
  }

  .introText {
    height: 7em;
  }

  .introText.top, .introText.bottom {
    height: 1.5em;
  }

  .introText.top h1, .introText.bottom h1, #author em {
    font-size: 1.5em;
  }

  #darkModeToggle {
    top: 3em;
  }

  #switch {
    width: 3em;
    height: 3em;
  }

  input {
    width: 4em;
    height: 4em;
  }

  .moon {
    width: 3em;
    height: 3em;
    left: 90%;
  }

  p, li {
    font-size: .9em;
  }

  i {
    white-space: nowrap;
    font-size: 1em;
  }

  #overlay .overlay__content__fr, #overlay .overlay__content__en {
    grid-row: 4 / span 6;
  }

  #overlay .overlay__content h2 {
    grid-area: 1 / 2 / span 3 / 10;
    font-size: 3em;
  }

  #overlay .overlay__content .lang-fr, #overlay .overlay__content .lang-en {
    grid-row: 3 / span 1;
    align-self: flex-end;
    font-size: 1.2em;
  }

  #menuButton {
    font-size: 1.2em;
    bottom: 2em;
  }

  .credits_content__pagination {
    display: none;
  }

  .credits_content__title {
    grid-area: 4 / 3 / span 2 / span 8;
  }

  .credits_content__title sup {
    padding: 0 4em;
    font-size: 1em;
  }

  .credits_content__title h3 {
    font-size: 5em;
  }

  .credits_content__quote {
    display: none;
  }

  .credits_content__text {
    column-count: 2;
    grid-area: 6 / 3 / span 6 / span 8;
    align-self: center;
    gap: 4em;
  }

  .credits_content__text p {
    margin-bottom: 1.2em;
    line-height: 1.2em;
  }
}

@media (width >= 1024px) and (width <= 1024px) and (orientation: landscape) {
  h1 {
    font-size: 8em;
  }

  .introContainer {
    width: 500px;
    height: 250px;
  }

  .introText {
    height: 7em;
  }

  .introText.top, .introText.bottom {
    height: 1.5em;
  }

  .introText.top h1, .introText.bottom h1, #author em {
    font-size: 1.5em;
  }

  #darkModeToggle {
    top: 3em;
  }

  #switch {
    width: 3em;
    height: 3em;
  }

  input {
    width: 4em;
    height: 4em;
  }

  .moon {
    width: 3em;
    height: 3em;
    left: 90%;
  }

  p, li {
    font-size: 1em;
  }

  i {
    white-space: nowrap;
    font-size: 1em;
  }

  #overlay .overlay__content__fr, #overlay .overlay__content__en {
    grid-row: 4 / span 6;
  }

  #overlay .overlay__content h2 {
    grid-area: 2 / 2 / span 3 / 10;
    font-size: 4em;
  }

  #overlay .overlay__content .lang-fr, #overlay .overlay__content .lang-en {
    grid-row: 4 / span 1;
    align-self: flex-end;
    font-size: 1.5em;
  }

  #menuButton {
    font-size: 1.5em;
    bottom: 2em;
  }

  .credits_content__pagination {
    grid-area: 3 / 2 / span 9 / span 3;
    align-self: flex-start;
  }

  .credits_content__pagination ol li {
    font-size: 1em;
    line-height: 1.2em;
  }

  .credits_content__title sup {
    padding: 0 1.5em;
    font-size: 1em;
  }

  .credits_content__title h3 {
    font-size: 3.5em;
  }

  .credits_content__quote p {
    font-size: 1em;
  }

  .credits_content__text {
    grid-area: 4 / -4 / span 9 / span 2;
    align-self: center;
  }

  .credits_content__text p {
    margin-bottom: 1.1em;
    line-height: 1.1em;
  }
}

@media (width >= 1025px) and (width <= 1366px) and (orientation: landscape) {
  h1 {
    font-size: 8em;
  }

  .introContainer {
    width: 500px;
    height: 250px;
  }

  .introText {
    height: 7em;
  }

  .introText.top, .introText.bottom {
    height: 1.5em;
  }

  .introText.top h1, .introText.bottom h1, #author em {
    font-size: 1.5em;
  }

  #darkModeToggle {
    top: 3em;
  }

  #switch {
    width: 3em;
    height: 3em;
  }

  input {
    width: 4em;
    height: 4em;
  }

  .moon {
    width: 3em;
    height: 3em;
    left: 90%;
  }

  p, li {
    font-size: 1em;
  }

  i {
    white-space: nowrap;
    font-size: 1em;
  }

  #overlay .overlay__content__fr, #overlay .overlay__content__en {
    grid-row: 4 / span 6;
  }

  #overlay .overlay__content h2 {
    grid-area: 1 / 2 / span 3 / 10;
    font-size: 4em;
  }

  #overlay .overlay__content .lang-fr, #overlay .overlay__content .lang-en {
    grid-row: 3 / span 1;
    align-self: flex-end;
    font-size: 1.2em;
  }

  #menuButton {
    font-size: 1.2em;
    bottom: 2em;
  }

  .credits_content__pagination {
    grid-area: 3 / 2 / span 9 / span 3;
    align-self: flex-start;
  }

  .credits_content__pagination ol li {
    font-size: 1em;
    line-height: 1.8em;
  }

  .credits_content__title sup {
    padding: 0 1em;
    font-size: 1em;
  }

  .credits_content__title h3 {
    font-size: 5em;
  }

  .credits_content__quote {
    grid-area: 3 / -4 / span 2 / span 2;
    align-self: flex-start;
  }

  .credits_content__quote p {
    font-size: 1em;
  }
}

@media (width >= 1025px) and (width <= 1366px) {
  h1 {
    font-size: 8em;
  }

  .introContainer {
    width: 500px;
    height: 300px;
  }

  .introText {
    height: 7em;
  }

  .introText.top, .introText.bottom {
    height: 1.5em;
  }

  .introText.top h1, .introText.bottom h1, #author em {
    font-size: 1.5em;
  }

  #darkModeToggle {
    top: 3em;
  }

  #switch {
    width: 3em;
    height: 3em;
  }

  input {
    width: 4em;
    height: 4em;
  }

  .moon {
    width: 3em;
    height: 3em;
    left: 90%;
  }

  p, li {
    font-size: 1em;
  }

  i {
    white-space: nowrap;
    font-size: 1em;
  }

  #overlay .overlay__content__fr, #overlay .overlay__content__en {
    grid-row: 4 / span 6;
  }

  #overlay .overlay__content h2 {
    grid-area: 1 / 2 / span 3 / 10;
    font-size: 4em;
  }

  #overlay .overlay__content .lang-fr, #overlay .overlay__content .lang-en {
    grid-row: 3 / span 1;
    align-self: flex-end;
    font-size: 1.2em;
  }

  #menuButton {
    font-size: 1.2em;
    bottom: 2em;
  }

  .credits_content__pagination {
    grid-area: 4 / 2 / span 6 / span 3;
    align-self: center;
  }

  .credits_content__pagination ol li {
    font-size: 1em;
    line-height: 1.3em;
  }

  .credits_content__title sup {
    padding: 0 3em;
    font-size: .9em;
  }

  .credits_content__title h3 {
    font-size: 3em;
  }

  .credits_content__text {
    grid-area: 4 / -4 / span 9 / span 2;
    align-self: center;
  }

  .credits_content__text p {
    margin-bottom: 1.2em;
    line-height: 1.2em;
  }

  .credits_content__quote {
    grid-area: 3 / -4 / span 2 / span 2;
    align-self: flex-start;
  }

  .credits_content__quote p {
    font-size: 1.1em;
  }
}

@media (width >= 1367px) and (width <= 1441px) {
  #overlay .overlay__content i {
    white-space: nowrap;
  }

  .credits_content__pagination {
    grid-area: 3 / 2 / span 9 / span 3;
    align-self: flex-start;
  }

  .credits_content__pagination ol li {
    font-size: 1em;
    line-height: 1.3em;
  }

  .credits_content__text {
    text-transform: uppercase;
    grid-area: 8 / -4 / span 3 / span 2;
    align-self: center;
  }

  .credits_content__text p {
    margin-bottom: 1.2em;
    line-height: 1.3em;
  }
}

@media (width >= 1441px) {
  h1 {
    font-size: 8em;
  }

  .introText.top, .introText.bottom {
    height: 2em;
  }

  .introText.top h1, .introText.bottom h1 {
    font-size: 1.5em;
  }

  .introText {
    height: 7em;
  }

  .introContainer img {
    aspect-ratio: 16 / 9;
    object-fit: cover;
  }

  #author em {
    font-size: 1.5em;
  }

  #darkModeToggle {
    top: 2em;
  }

  #menuButton {
    font-size: 1.5em;
    bottom: 1.5em;
  }

  #overlay .overlay__content h2 {
    grid-area: 2 / 2 / span 3 / 10;
    font-size: 4.5em;
  }

  #overlay .overlay__content .lang-fr, #overlay .overlay__content .lang-en {
    align-self: flex-end;
    font-size: 1.2em;
  }

  #overlay .overlay__content p {
    font-size: 1em;
  }

  .credits_content__pagination {
    grid-area: 2 / 2 / span 10 / span 3;
    align-self: center;
  }

  .credits_content__pagination ol li {
    font-size: 1em;
    line-height: 1.3em;
  }

  .credits_content__title {
    grid-area: 6 / 5 / span 2 / span 4;
  }

  .credits_content__title sup {
    padding: 0 3em;
    font-size: 1.2em;
    top: -1em;
  }

  .credits_content__title h3 {
    font-size: 5em;
  }

  .credits_content__quote {
    grid-area: 3 / -4 / span 2 / span 2;
    align-self: center;
  }

  .credits_content__quote p {
    font-size: 1em;
  }

  .credits_content__text {
    grid-area: 6 / -4 / span 6 / span 2;
    align-self: center;
  }

  .credits_content__text p {
    margin-bottom: 1.2em;
    line-height: 1em;
  }
}

@media (width >= 1920px) {
  .a, .gallery-item .b, .single-item .c {
    width: 70%;
  }

  .d {
    width: 55%;
  }

  .single-item .b {
    width: 40%;
  }

  .e {
    width: 35%;
    height: auto;
  }
}

.has-scroll-smooth {
  position: fixed;
  inset: 0;
}

html.has-scroll-smooth {
  overflow: hidden;
}

html.has-scroll-dragging {
  -webkit-user-select: none;
  user-select: none;
}

.has-scroll-smooth body {
  overflow: hidden;
}

[data-scroll-direction="horizontal"] [data-scroll-section] {
  vertical-align: top;
  white-space: nowrap;
}
/*# sourceMappingURL=index.922d2c63.css.map */
