  @font-face {
    font-family: 'Karbon';
    src: url('../assets/fonts/karbon-hairline.woff2') format('woff2');
    font-weight: 100; /* Hairline weight */
    font-style: normal;
  }
  
  @font-face {
    font-family: 'Inter';
    src: url('../assets/fonts/Inter-Thin.ttf') format('truetype');
    font-weight: 100; /* Thin weight */
  }

  @font-face {
    font-family: 'Daniel';
    src: url('../assets/fonts/daniel.ttf') format('truetype');
    font-weight: 400; /* Regular weight */
  }


